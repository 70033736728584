import axios, { AxiosRequestConfig } from "axios"
import { Action } from "redux"
import { all, call, takeLatest, put } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import { IHydraCollection } from "@modules/backend-definitions/src/models/hydra"
import { BASIC_AUTH_SINN } from "config"

import { setOIDCProvidersAction, setOIDCTokenAction, setPlatformAuthReplyAction } from "./reducer"
import { getOIDCConfig } from "../config"
import { IOIDCProvider } from "../models/IOIDCProvider"
import { OIDCRequestScopes } from "../models/request-states"
import { clearOIDCItemsFromStorage } from "../utils/util"

// #region saga actions

/**
 * Types of actions and corresponding sagas to interact with the host application backend
 * and the OIDC provider.
 */
enum OIDCSagaActionTypes {
  LoadOIDCProviders = "LOAD_OIDC_PROVIDERS",
  FetchOIDCToken = "FETCH_OIDC_TOKEN",
  LoginWithOIDCToken = "LOGIN_WITH_OIDC_TOKEN",
}

/**
 * Abstract base interface of actions that trigger a saga within the OIDC context.
 */
interface IOIDCSagaAction extends Action {
  type: OIDCSagaActionTypes
}

/**
 * Interface of an action that triggers the loading of a list of supported OIDC providers
 * from the host application backend API.
 */
interface ILoadOIDCProvidersAction extends IOIDCSagaAction {
  type: OIDCSagaActionTypes.LoadOIDCProviders
}

/**
 * Interface of an action that triggers the fetching of the OIDC ID token from the OIDC provider.
 */
interface IFetchOIDCTokenAction extends IOIDCSagaAction {
  provider: IOIDCProvider
  authCode: string
  codeVerifier: string
  type: OIDCSagaActionTypes.FetchOIDCToken
}

/**
 * Interface of an action that triggers the login at the host application backend
 * by using an OIDC ID token.
 */
interface ILoginWithOIDCTokenAction extends IOIDCSagaAction {
  /** OIDC provider that provides the OIDC ID token. */
  provider: IOIDCProvider
  /** OIDC ID token that has been returned from the OIDC provider after the user authenticated there. */
  idToken: string
  type: OIDCSagaActionTypes.LoginWithOIDCToken
}

/**
 * Creates an action to load OIDC provider information from the host application backend.
 */
export const loadOIDCProvidersAction = (): ILoadOIDCProvidersAction => ({
  type: OIDCSagaActionTypes.LoadOIDCProviders
})

/**
 * Creates an action to fetch the OIDC ID token from the OIDC provider, using a matching `authCode`.
 * NOTE: the saga calls OIDC provider's endpoint instead of the host application backend API.
 */
export const fetchOIDCTokenAction = (provider: IOIDCProvider, authCode: string, codeVerifier: string): IFetchOIDCTokenAction => ({
  provider,
  authCode,
  codeVerifier,
  type: OIDCSagaActionTypes.FetchOIDCToken
})

/**
 * Creates an action to login on the host application backend with an OIDC id token from a known OIDC provider.
 */
export const loginWithOIDCTokenAction = (provider: IOIDCProvider, idToken: string): ILoginWithOIDCTokenAction => ({
  provider,
  idToken,
  type: OIDCSagaActionTypes.LoginWithOIDCToken
})

// #endregion

// #region sagas

export function* oidcWatcherSaga(): any {
  yield all([
    takeLatest(OIDCSagaActionTypes.LoadOIDCProviders, withCallback(loadOIDCProvidersSaga)),
    takeLatest(OIDCSagaActionTypes.FetchOIDCToken, withCallback(fetchOIDCTokenSaga)),
    takeLatest(OIDCSagaActionTypes.LoginWithOIDCToken, withCallback(loginWithOIDCTokenSaga)),
  ])
}

/**
 * Loads supported providers from the host application backend.
 */
function* loadOIDCProvidersSaga(action: ILoadOIDCProvidersAction): Generator<any, IHydraCollection<IOIDCProvider>, any> {
  try {

    getOIDCConfig().loggerAPI.debug("loadOIDCProvidersSaga: action", action)

    yield put(getOIDCConfig().requestStateAPI.taskStartedAction(OIDCRequestScopes.LoadOIDCProviders))

    const oidcProviders: IHydraCollection<IOIDCProvider> = yield call(getOIDCConfig().oidcAPI.getOIDCProviders)

    getOIDCConfig().loggerAPI.debug("loadOIDCProvidersSaga: oidcProviders", oidcProviders)

    yield put(setOIDCProvidersAction(oidcProviders))

    yield put(getOIDCConfig().requestStateAPI.taskSucceededAction(OIDCRequestScopes.LoadOIDCProviders))

    return oidcProviders
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : getOIDCConfig().getUnknownErrorCode()
    getOIDCConfig().handleSagaError("loadOIDCProvidersSaga", errorMessage, null, err)

    getOIDCConfig().loggerAPI.debug("loadOIDCProvidersSaga: errorMessage", errorMessage)

    yield put(getOIDCConfig().requestStateAPI.taskFailedAction(OIDCRequestScopes.LoadOIDCProviders, errorMessage))

    yield put(setOIDCProvidersAction(null))

    return null
  }
}

/**
 * Fetches an OIDC ID token from an OIDC provider, using a matching `authCode`.
 */
function* fetchOIDCTokenSaga(action: IFetchOIDCTokenAction): Generator<any, string, any> {
  try {

    getOIDCConfig().loggerAPI.debug("fetchOIDCTokenSaga: action", action)

    yield put(getOIDCConfig().requestStateAPI.taskStartedAction(OIDCRequestScopes.FetchOIDCToken))

    /**
     * Params to be added to the API request to the OIDC provider.
     *
     * NOTE: the param names are standardized for interaction the OIDC provider.
     *
     * @todo oauth refactor the param structure to an (exported) type.
     */
    const urlParams = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: action.provider.clientId,
      code: action.authCode,
      redirect_uri: getOIDCConfig().redirectURI,
      code_verifier: action.codeVerifier
    })

    getOIDCConfig().loggerAPI.debug("fetchOIDCTokenSaga: urlParams", urlParams)

    let requestConfig: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    // #region add BasicAuth to request config, if required
    if (BASIC_AUTH_SINN) {
      const [basicAuthUser, basicAuthPassword] = Buffer
        .from(BASIC_AUTH_SINN, "base64")
        .toString().split(":")

      requestConfig = {
        ...requestConfig,
        auth: {
          username: basicAuthUser,
          password: basicAuthPassword
        }
      }
    }
    // #endregion

    getOIDCConfig().loggerAPI.debug("fetchOIDCTokenSaga: requestConfig", requestConfig)

    // @todo oauth how do we get rid of "unbound method" error, when at the same time calling apiClient's methods
    // and getOIDCConfig().getOIDCProviders works without error?
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const response = yield call(axios.post, action.provider.tokenUrl, urlParams, requestConfig)

    getOIDCConfig().loggerAPI.debug("fetchOIDCTokenSaga: response", response)

    const { id_token: idToken } = response.data

    yield put(setOIDCTokenAction(idToken))

    yield put(getOIDCConfig().requestStateAPI.taskSucceededAction(OIDCRequestScopes.FetchOIDCToken))

    return idToken as string
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : getOIDCConfig().getUnknownErrorCode()
    getOIDCConfig().handleSagaError("fetchOIDCTokenSaga", errorMessage, null, err)

    getOIDCConfig().loggerAPI.debug("fetchOIDCTokenSaga: errorMessage", errorMessage)

    yield put(getOIDCConfig().requestStateAPI.taskFailedAction(OIDCRequestScopes.FetchOIDCToken, errorMessage))

    yield put(setOIDCTokenAction(null))
    return null
  }
}


/**
 * Performs a login at the host application backend, using an OIDC ID token retrieved earlier.
 */
function* loginWithOIDCTokenSaga<AuthType = any>(action: ILoginWithOIDCTokenAction): Generator<any, AuthType, any> {
  try {

    getOIDCConfig().loggerAPI.debug("loginWithOIDCTokenSaga: action", action)

    yield put(getOIDCConfig().requestStateAPI.taskStartedAction(OIDCRequestScopes.LoginWithOIDCToken))

    const platformAuthReply: AuthType = yield call(getOIDCConfig().oidcAPI.loginWithOIDCToken, action.provider.shortName, action.idToken)

    getOIDCConfig().loggerAPI.debug("loginWithOIDCTokenSaga: platformAuthReply", platformAuthReply)

    yield put(setPlatformAuthReplyAction(platformAuthReply))

    yield put(getOIDCConfig().requestStateAPI.taskSucceededAction(OIDCRequestScopes.LoginWithOIDCToken))

    // We won't need the stored items no more.
    // NOTE this could also be done in an onSuccess-callback provided via action (e.g. from the calling hook),
    // or maybe by using some requestState-interpretation (also in the calling hook).
    clearOIDCItemsFromStorage()

    return platformAuthReply
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : getOIDCConfig().getUnknownErrorCode()
    getOIDCConfig().handleSagaError("loginWithOIDCTokenSaga", errorMessage, null, err)

    getOIDCConfig().loggerAPI.debug("loginWithOIDCTokenSaga: errorMessage", errorMessage)

    yield put(getOIDCConfig().requestStateAPI.taskFailedAction(OIDCRequestScopes.LoginWithOIDCToken, errorMessage))

    yield put(setPlatformAuthReplyAction(null))
    return null
  }
}

// #endregion