import { FormikHelpers } from "formik"
import { Action } from "redux"

import { IAuthReply, IHydraCollection, IModel, INumericIdentifierModel, IRI, UserRole } from "@api/schema"
import { IStatistics } from "@api/schema/statistics"
import {
  ActionTypes,
  CreateableEntityType,
  DeleteableEntityType,
  EntityType,
  GeneralApiActionTypes,
  LoadableEntityType,
  ScopeTypes,
  StatisticsType,
  UpdateableEntityType,
  UploadType,
} from "@redux/reduxTypes"
import { entityTypeFromIModelOrIRI } from "@services/util"

/**
 * Action are central elements in Redux: "An Action is a plain object that represents the intention to change the state.
 * Actions are the only way to get data into the store. Any data ... needs to be dispatched as Actions."
 *
 * This collection of Actions can be used to trigger the loading and altering of data from/to the API.
 * It defines all needed basic Actions to load, update and delete Entities and Collection of Entities, as well
 * as report success or failure.
 */


/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */



/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

/**
 * IFormikActions are used in every Formik-based form to report back succesful or non-successful submissions
 */
export interface IFormikActions extends Partial<FormikHelpers<any>> {
  /**
   * Coder should define a function and assign it to onSucces in the onSubmit-Function for a Formik-Form,
   * that should be called in the corresponding Saga, when a request was succesful.
   */
  onSuccess?: (...args: any) => void
}

/**
 * IResultType defines possible Results for RequestSuccessActions
 * NOTE / @TODO we'd like to use INumericIdentifierModel instead of IModel, but IProjectMembership doesn't implement it
 */
export type IResultType = boolean | IModel | IHydraCollection<IModel> | IStatistics | IAuthReply

/**
 * An empty collection that is of type IResultType
 */
export const emptyCollection: IResultType = { "hydra:member": [] }

/**
 * IUsecaseReference is an interface that references an usecase by its key.
 */
// was: export interface IScopeAction extends Action {
export interface IUsecaseReference {
  usecaseKey: string
}

/**
 * A IUsecaseRequestRunningAction extends IUsecaseReference by data about the loading state and possible errors.
 * It represents the state of an usecase scoped request (mostly to the API), that may still be running/loading or not and may
 * resulted in errors.
 * NOTE sending this action without an error means the request ist loading. With an error, the request is cancelled / not loading.
 */
// was: export interface IRequestRunningAction extends IScopeAction {
export interface IUsecaseRequestRunningAction<ActionType extends string = string> extends Action<ActionType>, IUsecaseReference {
  error: string
}

/**
 * An IUsecaseRequestSuccessAction extends IUsecaseReference by results of a successful request,
 * and if those results should be appended to existing data.
 */
// was: export interface IRequestSuccessAction extends IScopeAction {
export interface IUsecaseRequestSuccessAction<ActionType extends string = string> extends Action<ActionType>, IUsecaseReference {
  result: IResultType
  append: boolean
}

/**
 * interface to easyly define filter-criterias/parameters to be send to the API to filter the results, e.G. {id}
 * see: example at loadModelAction()
 */
export interface IFilterCriteria {
  [property: string]: any
}

/**
 * An IEntityTypeReference is an interface that references an EntityType by its typed name.
 */
// was: export interface IEntityAction extends IScopeAction {
export interface IEntityTypeReference<E extends EntityType> {
  entityType: E // identifier for an EntityType
}

/**
 * An IEntityApiRequestInvokingAction is an Action that leads to an API request.
 * It refers to the EntityType that this operation will run for,
 * and to an (optional) usecase that will be used to signal usecase completion (through usecaseRequestSuccessAction).
 */
export interface IEntityApiRequestInvokingAction<E extends EntityType, ActionType extends string> extends Action<ActionType>, IUsecaseReference, IEntityTypeReference<E> {
  /**
   * IRI of a parent entity in case that sub resources are loaded or an action should be performed to a sub-endpoint
   * of the given parent IRI
   */
  parentIri?: IRI
}

/**
 * Defines all possible success request types for api actions.
 * @see ModelRequestActionType
 */
type ModelRequestSuccessActionType = GeneralApiActionTypes.CreateSuccess
  | GeneralApiActionTypes.DeleteSuccess
  | GeneralApiActionTypes.UpdateSuccess
  | GeneralApiActionTypes.LoadSuccess

/**
 * EntityRequestSuccessActionType contains all entity request success types and the success request type
 * for loading a collection.
 */
type EntityRequestSuccessActionType = ModelRequestSuccessActionType
  | GeneralApiActionTypes.LoadCollectionSuccess

/**
 * Defines all possible request types general api actions.
 * In difference to ModelRequestSuccessActionType this type of api action is triggered first and if the api
 * action was successful like "GeneralApiActionTypes.Create", afterwards an api action
 * of the type "GeneralApiActionTypes.CreateSuccess" will be triggered.
 * @see ModelRequestSuccessActionType
 */
type ModelRequestActionType = GeneralApiActionTypes.Create
  | GeneralApiActionTypes.Delete
  | GeneralApiActionTypes.Load
  | GeneralApiActionTypes.Update
  | GeneralApiActionTypes.Upload

/**
 * An IEntityApiRequestSuccessAction is an Action that leads to an API request.
 * It refers to the EntityType that this operation has been run for.
 */
export interface IEntityApiRequestSuccessAction<ActionType extends EntityRequestSuccessActionType = EntityRequestSuccessActionType> extends Action<ActionType>, IEntityTypeReference<EntityType> {
}

/**
 * A IFilterContainer is an interface that contains an IFilterCriteria.
 */
export interface IFilterContainer {
  criteria: IFilterCriteria
}

/**
 * A ILoadSingleEntityByAction is an IEntityApiRequestInvokingAction that loads a single model element / entity.
 */
// was: export interface ILoadByAction extends IEntityAction {
export interface ILoadSingleEntityByAction extends IFilterContainer, IEntityApiRequestInvokingAction<LoadableEntityType, GeneralApiActionTypes.Load> {
  /**
   * if neededUsedRole is set the api fetch result should have the neededUsedRole in its usedRoles property, otherwise the saga
   * should provide an error
   *
   * @todo multi: upgrade to usedPrivileges
   */
  neededUsedRole?: UserRole
}

/**
 * A ILoadCollectionByAction is an IEntityApiRequestInvokingAction that loads a collection of model elements / entities.
 */
export interface ILoadCollectionByAction extends IFilterContainer, IEntityApiRequestInvokingAction<LoadableEntityType, GeneralApiActionTypes.LoadCollection> {
  /**
   * should all pages of entities be loaded, to make sure all entities of the given criteria are in the state
   */
  loadAll: boolean
  /**
   * if neededUsedRole is set the api fetch result should have the neededUsedRole in its usedRoles property, otherwise the saga
   * should provide an error
   *
   * @todo multi: upgrade to usedPrivileges
   */
  neededUsedRole?: UserRole
}

/**
 * An LoadPageAction extends an EntityAction by an URL to be loaded, especially when loading next pages of entities.
 */
// was: export interface ILoadPageAction extends IEntityAction {
export interface ILoadCollectionPageAction extends IEntityApiRequestInvokingAction<LoadableEntityType, GeneralApiActionTypes.LoadCollectionPage> {
  url: string
}

/**
 * An IModelContainer is an interface that contains a Model<T> object.
 * NOTE / @TODO we'd like to use INumericIdentifierModel instead of IModel, but IProjectMembership doesn't implement it
 */
export interface IModelContainer<T extends IModel> {
  /** the specific entity for which the upload is performed */
  model: T
}


/**
 * An IModelOperationSuccessAction extends an IEntityApiRequestSuccessAction and is a IModelContainer.
 * This interface is used for CREATE, LOAD(_COLLECTION), UPDATE and DELETE operations.
 */
export interface IModelCLUDOperationSuccessAction<T extends IModel, ActionType extends ModelRequestSuccessActionType = ModelRequestSuccessActionType> extends IModelContainer<T>, IEntityApiRequestSuccessAction<ActionType> {
}

/**
 * Contains data of form callback actions that may be called by a saga.
 */
export interface ICallbackActionContainer {
  callbackActions?: IFormikActions
}

/**
 * An IModelRequestActionWithCallback models "common" API request actions that regard an entity and provide form callback functions.
 * It extends an IEntityApiRequestInvokingAction and is a IModelContainer and a ICallbackActionContainer.
 */
export interface IModelRequestActionWithCallback<T extends IModel, E extends EntityType, ActionType extends ModelRequestActionType> extends IModelContainer<T>, ICallbackActionContainer, IEntityApiRequestInvokingAction<E, ActionType> {
}

/**
 * An IModelCreateOperationInvokingAction is a IModelRequestActionWithCallback for createable entities.
 */
export type IModelCreateOperationInvokingAction<T extends IModel> = IModelRequestActionWithCallback<T, CreateableEntityType, GeneralApiActionTypes.Create>

/**
 * An IModelUpdateOperationInvokingAction is a IModelRequestActionWithCallback for updateable entities.
 */
export type IModelUpdateOperationInvokingAction<T extends IModel> = IModelRequestActionWithCallback<T, UpdateableEntityType, GeneralApiActionTypes.Update>

/**
 * An IModelDeleteOperationInvokingAction is a IModelRequestActionWithCallback for deletable entities.
 */
export type IModelDeleteOperationInvokingAction<T extends IModel> = IModelRequestActionWithCallback<T, DeleteableEntityType, GeneralApiActionTypes.Delete>

/**
 * A LoadCollectionSuccessAction extends an IEntityApiRequestSuccessAction by the collection of results, delivered by the API.
 * NOTE / @TODO we'd like to use INumericIdentifierModel instead of IModel, but IProjectMembership doesn't implement it
 */
export interface ILoadCollectionSuccessAction<T extends IModel> extends IEntityApiRequestSuccessAction<GeneralApiActionTypes.LoadCollectionSuccess> {
  collection: IHydraCollection<T>
}


/**
 * An IStatisticsApiRequestInvokingAction is an Action that leads to an API request
 * to call a statistics-endpoint defined in src/api/client.ts
 * It refers to the StatisticsType that this operation will run for,
 * and to an (optional) usecase that will be used to signal usecase completion (through usecaseRequestSuccessAction).
 */
export interface IStatisticsApiRequestInvokingAction extends Action<GeneralApiActionTypes.LoadStatistics>, IUsecaseReference {
  usecaseKey: StatisticsType
  id?: number
}

/**
 * An IUploadApiRequestInvokingAction is an Action that leads to an file uploading API request.
 * Available api endpoints are defined in src/api/client.ts
 * It refers to the EntityType that the upload should be performed for.
 */
export interface IUploadApiRequestInvokingAction<T extends IModel> extends IModelRequestActionWithCallback<T, EntityType, GeneralApiActionTypes.Upload> {
  /** the file that should be uploaded */
  file: File
  /** the type of the upload must be a selection from UploadType */
  uploadType: UploadType
}

// constants to be used to specify actions
// @todo: rework: create an enum from that?
// @todo does this need extra entries for creating valid scopetypes, insbesondere: _OPERATION?
// @todo cleanup after refactoring of the redux system
export const REQUEST_PREFIX = "REQUEST_"
export const RUNNING_SUFFIX = "_RUNNING"
export const SUCCESS_SUFFIX = "_SUCCESS"
export const LOAD_PREFIX = "LOAD_"
export const LOADING = "_LOADING"
export const COLLECTION_LOADING = "_COLLECTION_LOADING"
// export const CLEAR_LIST_PREFIX = "CLEAR_"
export const CREATE_PREFIX = "CREATE_"
export const DELETE_PREFIX = "DELETE_"
export const UPDATE_PREFIX = "UPDATE_"

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/*                                                                            */
/* all created actions have an type, that is a combination from               */
/* an action + the entity-type / entity-scope                                 */
/* @todo: is there a way to generalize this and make it more secure against   */
/* typos?                                                                     */
/* ************************************************************************** */

// #region old redux system request actions

/**
 * Creates an IUsecaseRequestRunningAction that signalises if a usecase request is running or has stopped.
 * Creating this action without an error means the request ist running/loading.
 * With an error, the request is cancelled / not loading.
 *
 * @param usecaseKey usecaseKey of the request
 * @param error are there any errors, that should be reported, e.g. when a request has failed and the signal is: stopped running with errors
 * @returns a IUsecaseRequestRunningAction to be dispatched
 */
export const usecaseRequestRunningAction =
  (usecaseKey: ScopeTypes | string, error: string = null): IUsecaseRequestRunningAction => ({
    error,
    usecaseKey,
    type: REQUEST_PREFIX + usecaseKey.toUpperCase() + RUNNING_SUFFIX,
  })

export const usecaseRequestSuccessAction =
  (usecaseKey: string, result: IResultType, append = false): IUsecaseRequestSuccessAction => ({
    append,
    result,
    usecaseKey,
    type: REQUEST_PREFIX + usecaseKey.toUpperCase() + SUCCESS_SUFFIX,
  })

// #endregion

// #region old redux system action, that correspond more directly with API system - first part: SAGA triggering actions (from Application)

/**
 * Creates an LoadByAction to trigger the asynchroneous loading of a single Object,
 * filtered by given entityType and id.
 * A loaded Object is added to the redux-store, from where it can be filtered by (e.g.) selectCollection or selectByID
 * Loading a single Object ensures, that all available data is loaded and not only basic-data (-> detailResult === true)
 *
 * usage-example:
 * const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
 * ({ loadFund: (id: number) => dispatch(loadModelAction(EntityType.Fund, id)) })
 *
 * NOTE: ProjectMembership may not be loaded this way, because the API does not allow it (and it does not have an ID)
 *
 * @param entityType has to be a LoadableEntityType
 * @param id id of the entity
 * @param usecaseKey if usecaseKey is omitted, a default usecaseKey is created.
 * @param neededUsedRole the role with which the fetched entity should be delivered, otherwise the saga should provide an error; optional
 */
export const loadModelAction =
  (entityType: LoadableEntityType, id: number, usecaseKey?: string, neededUsedRole?: UserRole): ILoadSingleEntityByAction => ({
    criteria: { id },
    entityType,
    usecaseKey: usecaseKey || id.toString(),
    neededUsedRole,
    type: ActionTypes.Load,
  })

/**
 * A constant to avoid using in-line strings and implicit argument names.
 */
export const SLUG_OR_ID = "slugOrId"

/**
 * Creates an LoadByAction to trigger the asynchroneous loading of a single Object,
 * filtered by given entityType and slug.
 * A loaded Object is added to the redux-store, from where it can be filtered by (e.g.) selectCollection or selectByID
 * Loading a single Object ensures, that all available data is loaded and not only basic-data (-> detailResult === true)
 *
 * usage-example:
 * const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
 * ({ loadFund: (slug: string) => dispatch(loadModelAction(EntityType.Fund, slug)) })
 *
 * @param entityType has to be a LoadableEntityType
 * @param slugOrId id or slug of the entity
 * @param usecaseKey if usecaseKey is omitted, a default usecaseKey is created.
 */
export const loadModelBySlugOrIdAction =
  (entityType: LoadableEntityType, slugOrId: string, usecaseKey?: string): ILoadSingleEntityByAction => ({
    criteria: { [SLUG_OR_ID]: slugOrId }, // NOTE using this constant here to make it explicit that this is a non-default criterium
    entityType,
    usecaseKey: usecaseKey || slugOrId,
    type: ActionTypes.Load, // LOAD_PREFIX + entityType.toUpperCase(),
  })


/**
 * Creates an IModelCUDOperationInvokingAction to trigger the creation of an Object of the given EntityType
 */
export const createModelAction =
  (entityType: CreateableEntityType, model: IModel, actions: IFormikActions, parentIri?: IRI, /* , usecaseKey?: string */): IModelCreateOperationInvokingAction<IModel> => ({
    callbackActions: actions,
    entityType,
    model,
    parentIri,
    // NOTE short cut, since we do not assume creating more than 1 object per entity at a time
    usecaseKey: ActionTypes.Create,
    type: ActionTypes.Create,
  })

/**
 * Creates an IModelCUDOperationInvokingAction to update an object of the given EntityType
 *
 * NOTE: usecase key must be calculated by usecaseKeyForUpdateModel(model: IModel)
 */
export const updateModelAction =
  (entityType: UpdateableEntityType, model: IModel, actions: IFormikActions): IModelUpdateOperationInvokingAction<IModel> => ({
    callbackActions: actions,
    entityType,
    model,
    usecaseKey: usecaseKeyForUpdateModel(model),
    type: ActionTypes.Update,
  })

/**
 * Creates an IModelCUDOperationInvokingAction to delete an object of the given EntityType
 */
export const deleteModelAction =
  (entityType: DeleteableEntityType, model: IModel, actions?: IFormikActions): IModelDeleteOperationInvokingAction<IModel> => ({
    callbackActions: actions,
    entityType,
    model,
    usecaseKey: usecaseKeyForDeleteModel(model),
    type: ActionTypes.Delete,
  })

// #endregion

// #region old redux system action, that correspond more directly with API system - second part: REDUCER triggering actions (from SAGAs)

/**
 * Creates an ILoadCollectionSuccessAction to signalize the successful loading of a Collection
 *
 * do not touch: is used by scopedEntityReducer to fill (app)state.data
 *
 */
export const loadCollectionSuccessAction =
  (entityType: EntityType, collection: IHydraCollection<IModel>): ILoadCollectionSuccessAction<IModel> => ({
    collection,
    entityType,
    type: ActionTypes.LoadCollectionSuccess, // LOAD_PREFIX + entityType.toUpperCase() + "_COLLECTION" + SUCCESS_SUFFIX,
  })

/**
 * Creates an IModelCLUDOperationSuccessAction to signalize the successful loading of a single entity
 *
 * do not touch: is used by scopedEntityReducer to fill (app)state.data
 *
 */
export const loadModelSuccessAction =
  (entityType: EntityType, model: IModel): IModelCLUDOperationSuccessAction<IModel, GeneralApiActionTypes.LoadSuccess> => ({
    entityType,
    model,
    type: ActionTypes.LoadSuccess, // LOAD_PREFIX + entityType.toUpperCase() + SUCCESS_SUFFIX,
  })

/**
 * Creates an IModelCLUDOperationSuccessAction to signalize the successful creation of an object to the scopedObjectReducer
 *
 * Is used by a SAGA and (mainly) processed by a corresponding reducer
 *
 * do not touch: is used by scopedEntityReducer to fill (app)state.data
 */
export const createModelSuccessAction =
  (entityType: EntityType, model: IModel): IModelCLUDOperationSuccessAction<IModel, GeneralApiActionTypes.CreateSuccess> => ({
    entityType,
    model,
    type: ActionTypes.CreateSuccess, // CREATE_PREFIX + entityType.toUpperCase() + SUCCESS_SUFFIX,
  })

/**
 * Creates an IModelCLUDOperationSuccessAction to signalize the successful update of an object
 *
 * Is used by a SAGA and (mainly) processed by a corresponding reducer
 *
 * do not touch: is used by scopedEntityReducer to fill (app)state.data
 *
 */
export const updateModelSuccessAction =
  (entityType: EntityType, model: IModel): IModelCLUDOperationSuccessAction<IModel, GeneralApiActionTypes.UpdateSuccess> => ({
    entityType,
    model,
    type: ActionTypes.UpdateSuccess, // UPDATE_PREFIX + entityType.toUpperCase() + SUCCESS_SUFFIX,
  })

/**
 * Creates an IModelCLUDOperationSuccessAction to signalize the successful deletion of an object
 *
 * Is used by a SAGA and (mainly) processed by a corresponding reducer
 *
 * do not touch: is used by scopedEntityReducer to fill (app)state.data
 *
 */
export const deleteModelSuccessAction =
  (entityType: EntityType, model: IModel): IModelCLUDOperationSuccessAction<IModel, GeneralApiActionTypes.DeleteSuccess> => ({
    entityType,
    model,
    type: ActionTypes.DeleteSuccess, // DELETE_PREFIX + entityType.toUpperCase() + SUCCESS_SUFFIX,
  })

// #endregion

// ////////////////////////////////////////////////////////////////////////////////////////
// new Actions for new entityUsecaseReducer state/reducer

// #region INewUsecaseRequestAction interface family

/**
 * A INewUsecaseRequestAction is an Action that has a IUsecaseReference and a IEntityTypeReference.
 * It represents the state of an usecase scoped request for a certain EntityType (mostly to the API).
 * Its attribute isCollection allows to inform about whether it is a Collection or a SingleModel usecase.
 * (Note/Todo: this is not clean architecture, but the only way to differentiate between two types b/c of Javascript)
 */
interface INewUsecaseRequestAction<ActionType extends string> extends Action<ActionType>, IUsecaseReference, IEntityTypeReference<EntityType> {
  isCollection: boolean
}

/**
 * A INewUsecaseRequestRunningAction extends INewUsecaseRequestAction by data about the loading state and possible errors.
 * It represents the state of an usecase scoped request (mostly to the API), that may still be running/loading or not and may
 * resulted in errors.
 */
export interface INewUsecaseRequestRunningAction extends INewUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestRunning> {
  error: string
}

/**
 * An INewUsecaseRequestSuccessAction extends INewUsecaseRequestAction by results of a successful request.
 * It represents the state of an usecase scoped request (mostly to the API), that is finished.
 */
export interface INewUsecaseRequestSuccessAction extends INewUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestSuccess> {
  /**
   * Result value of a successful request
   */
  result: IResultType
}

/**
 * An INewSingleEntityUsecaseRequestAction extends INewUsecaseRequestAction to model that only SingleEntities are meant.
 * It's abstract and super interface of both Running as well as Success sub interfaces.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface INewSingleEntityUsecaseRequestAction<ActionType extends string> extends INewUsecaseRequestAction<ActionType> { }

/**
 * A INewSingleEntityUsecaseRequestRunningAction extends INewUsecaseRequestRunningAction, INewSingleEntityUsecaseRequestAction
 * by data about the loading state and possible errors.
 * It represents the state of an usecase scoped request (mostly to the API), that may still be running/loading or not and may
 * resulted in errors.
 */
interface NewSingleEntityUsecaseRequestRunningAction extends INewUsecaseRequestRunningAction, INewSingleEntityUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestRunning> {
}

/**
 * An INewSingleEntityUsecaseRequestSuccessAction extends INewUsecaseRequestSuccessAction, INewSingleEntityUsecaseRequestAction
 * by results of a successful request.
 * It represents the state of an usecase scoped request (mostly to the API), that is finished.
 */
interface INewSingleEntityUsecaseRequestSuccessAction extends INewUsecaseRequestSuccessAction, INewSingleEntityUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestSuccess> {
  /**
   * Result value of a successful request
   * NOTE / @TODO we'd like to use INumericIdentifierModel instead of IModel, but IProjectMembership doesn't implement it
   */
  result: IModel
}

/**
 * An INewFilteredCollectionUsecaseRequestAction extends INewUsecaseRequestAction to model that filteredCollections are meant.
 * It's abstract and super interface of both Running as well as Success sub interfaces.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface INewFilteredCollectionUsecaseRequestAction<ActionType extends string> extends INewUsecaseRequestAction<ActionType> { }

/**
 * A INewFilteredCollectionUsecaseRequestRunningAction extends INewUsecaseRequestRunningAction, INewFilteredCollectionUsecaseRequestAction
 * by data about the loading state and possible errors.
 * It represents the state of an usecase scoped request (mostly to the API), that may still be running/loading or not and may
 * resulted in errors.
 */
interface NewFilteredCollectionUsecaseRequestRunningAction extends INewUsecaseRequestRunningAction, INewFilteredCollectionUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestRunning> {
}

/**
 * An INewSingleEntityUsecaseRequestSuccessAction extends INewUsecaseRequestSuccessAction, INewFilteredCollectionUsecaseRequestAction
 * by results of a successful request.
 * It represents the state of an usecase scoped request (mostly to the API), that is finished.
 * Its isPage flag informs about whether or not the result is coming from a follow-up page.
 */
export interface NewFilteredCollectionUsecaseRequestSuccessAction extends INewUsecaseRequestSuccessAction, INewFilteredCollectionUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestSuccess> {
  /**
   * Result value of a successful request
   * NOTE / @TODO we'd like to use INumericIdentifierModel instead of IModel, but IProjectMembership doesn't implement it
   */
  result: IHydraCollection<IModel>
  isPage: boolean
}

// #endregion

// #region INewUsecaseRequestAction creator functions

/**
 * Creates INewUsecaseRequestAction instances that signal if a usecase request is running or has stopped
 *
 * @param entityType entityType of the entity
 * @param usecaseKey usecaseKey of the request
 * @param error are there any errors, that should be reported, e.g. when a request has failed and the signal is: stopped running with errors
 * @returns a IUsecaseRequestRunningAction to be dispatched
 */
// was: export const requestRunningAction =
export const newSingleEntityUsecaseRequestRunningAction =
  (entityType: EntityType, usecaseKey: string, error: string = null): NewSingleEntityUsecaseRequestRunningAction => ({
    entityType,
    error,
    usecaseKey,
    isCollection: false,
    type: ActionTypes.NewUsecaseRequestRunning,
  })

// was: export const requestSuccessAction =
export const newSingleEntityUsecaseRequestSuccessAction =
  (entityType: EntityType, usecaseKey: string, result: IModel): INewSingleEntityUsecaseRequestSuccessAction => ({
    entityType,
    result,
    usecaseKey,
    isCollection: false,
    type: ActionTypes.NewUsecaseRequestSuccess,
  })

export const newFilteredCollectionUsecaseRequestRunningAction =
  (entityType: EntityType, usecaseKey: string, error: string = null): NewFilteredCollectionUsecaseRequestRunningAction => ({
    entityType,
    error,
    usecaseKey,
    isCollection: true,
    type: ActionTypes.NewUsecaseRequestRunning,
  })

export const newFilteredCollectionUsecaseRequestSuccessAction =
  (entityType: EntityType, usecaseKey: string, result: IHydraCollection<IModel>, isPage = false): NewFilteredCollectionUsecaseRequestSuccessAction => ({
    entityType,
    isCollection: true,
    isPage,
    result,
    type: ActionTypes.NewUsecaseRequestSuccess,
    usecaseKey,
  })

export type StatisticRequestActions = IUsecaseRequestRunningAction<GeneralApiActionTypes.LoadStatisticsRunning>
  | IUsecaseRequestSuccessAction<GeneralApiActionTypes.LoadStatisticsSuccess>

/**
 * Creates an IUsecaseRequestRunningAction that signalises if a usecase request is running or has stopped
 * Creating this action without an error means the request ist running/loading.
 * With an error, the request is cancelled / not loading.
 *
 * @param usecaseKey usecaseKey of the request
 * @param error are there any errors, that should be reported, e.g. when a request has failed and the signal is: stopped running with errors
 * @returns a IUsecaseRequestRunningAction to be dispatched
 */
export const statisticsRequestRunningAction =
  (usecaseKey: StatisticsType, error: string = null): IUsecaseRequestRunningAction<GeneralApiActionTypes.LoadStatisticsRunning> => ({
    error,
    usecaseKey,
    type: ActionTypes.LoadStatisticsRunning
  })


/**
 * Creates an IUsecaseRequestRunningAction that signalises if a usecase request successful
 *
 * @param usecaseKey usecaseKey of the request
 * @param result result of the request,
 * @returns a IUsecaseRequestRunningAction to be dispatched
 */
export const statisticsRequestSuccessAction =
  (usecaseKey: StatisticsType, result: IStatistics): IUsecaseRequestSuccessAction<GeneralApiActionTypes.LoadStatisticsSuccess> => ({
    append: false,
    result,
    usecaseKey,
    type: ActionTypes.LoadStatisticsSuccess
  })

// #endregion

// #region Model requests matching to INewUsecaseRequestAction/entityUsecaseReducer style

/**
 * Creates an ILoadCollectionByAction to trigger the asynchroneous loading of a collection of Objects,
 * filtered by given entityType and criteria, tagged with the given usecase.
 * This method matches the new INewUsecaseRequestAction/entityUsecaseReducer style
 */
export const newLoadCollectionAction =
  (entityType: LoadableEntityType, criteria: IFilterCriteria = {}, usecaseKey: string, parentIri?: IRI, loadAll = false, neededUsedRole?: UserRole): ILoadCollectionByAction => ({
    criteria,
    entityType,
    usecaseKey,
    type: ActionTypes.LoadCollection,
    loadAll,
    parentIri,
    neededUsedRole,
  })

/**
 * Creates an ILoadCollectionPageAction to load a given page of a collection, encoded within the given url
 * This method matches the new INewUsecaseRequestAction/entityUsecaseReducer style
 */
export const newLoadCollectionPageAction =
  (entityType: LoadableEntityType, url: string, usecaseKey: string): ILoadCollectionPageAction => ({
    entityType,
    usecaseKey,
    type: ActionTypes.LoadCollectionPage,
    url,
  })

// #endregion

/**
 * Creates an IStatisticsApiRequestInvokingAction to trigger the asynchroneous loading of statistics,
 * chosen by given statisticsType.
 */
export const loadStatisticsAction =
  (statisticsType: StatisticsType, id?: number): IStatisticsApiRequestInvokingAction => ({
    type: ActionTypes.LoadStatistics,
    usecaseKey: statisticsType,
    id,
  })


/**
 * Creates an IUploadApiRequestInvokingAction to trigger a saga to upload a file for an UploadType of an specific entity
 *
 * @param uploadType type of that upload
 * @param entity specific entity for which the upload should be performed
 * @param file file to be uploaded
 * @param actions formik form specific actions
 * @param usecaseKey a string as key for special use cases, otherwise a default value is set
 * @returns a IUploadApiRequestInvokingAction to be dispatched
 */
export const uploadFileAction =
  (uploadType: UploadType, entity: INumericIdentifierModel, file: File, actions: IFormikActions, usecaseKey = ScopeTypes.UploadFileOperation): IUploadApiRequestInvokingAction<INumericIdentifierModel> => ({
    callbackActions: actions,
    model: entity,
    entityType: entityTypeFromIModelOrIRI(entity),
    file,
    type: ActionTypes.Upload,
    uploadType,
    usecaseKey,
  })


/**
 * @returns the default usecase key for update actions on IModel entities
 */
export const usecaseKeyForUpdateModel = (model: IModel): string =>
  ActionTypes.Update + model?.["@id"]

/**
 * @returns the default usecase key for delete actions on IModel entities
 */
export const usecaseKeyForDeleteModel = (model: IModel): string =>
  ActionTypes.Delete + model?.["@id"]