import { IHydraCollection, INumericIdentifierModel } from "@api/schema"
import { IDerivedStatistics, IStatistics } from "@api/schema/statistics"
import { ActionTypes, EntityType, GlobalStatisticsType, ScopeTypes, SingleStatisticsType, StatisticsType } from "@redux/reduxTypes"

import {
  IEntityApiRequestSuccessAction,
  ILoadCollectionPageAction,
  ILoadCollectionSuccessAction,
  IModelCLUDOperationSuccessAction,
  INewUsecaseRequestRunningAction,
  INewUsecaseRequestSuccessAction,
  IUsecaseRequestRunningAction,
  IUsecaseRequestSuccessAction,
  NewFilteredCollectionUsecaseRequestSuccessAction,
  REQUEST_PREFIX,
  RUNNING_SUFFIX,
  SUCCESS_SUFFIX,
  StatisticRequestActions
} from "./actions"
import {
  IIndexedCollectionState,
  IRequestState,
  initialIndexedCollectionState,
  initialRequestState,
  REQUEST_STATE_SUCCESSFUL,
} from "./state"


/**
 * All possible ActionTypes for a running request for all ScopeTypes
 */
export type ScopeRequestTypeRunning = `${typeof REQUEST_PREFIX}${Uppercase<ScopeTypes>}${typeof RUNNING_SUFFIX}`

/**
 * All possible ActionTypes for a success request for all ScopeTypes
 */
export type ScopeRequestTypeSuccess = `${typeof REQUEST_PREFIX}${Uppercase<ScopeTypes>}${typeof SUCCESS_SUFFIX}`

// defines a RequestReducer (for states of the request-process)
// and an ObjectReducer (for the specific data of entities)

/**
 * IRequestActions may be of type IUsecaseRequestRunningAction (while requesting) or IUsecaseRequestSuccessAction (after successful request)
 */
export type IRequestActions = IUsecaseRequestRunningAction<ScopeRequestTypeRunning | ScopeRequestTypeSuccess>
  | IUsecaseRequestSuccessAction<ScopeRequestTypeRunning | ScopeRequestTypeSuccess>

/**
 * This Reducer is a general reducer for all kinds of requests to the API. It is used in src/redux/reducer/requests.ts
 * to combine a set of scopeRequestReducer for all possible Entitiy-Types and their operations with the API.
 *
 * It generates a request-state for every possible requests. Before accessing requested data, the request-state delivers
 * information, if the request is still in progress or finished, and if it was finished successful or not.
 *
 * @param scope The operation for which the reducer creates an IRequestState
 * @returns An IRequestState and an IRequestActions specialized to the given ScopeType
 */
export const scopedRequestReducer = (scope: ScopeTypes): (state: IRequestState, action: IRequestActions) => IRequestState => {
  const requestReducer =
    (state: IRequestState = initialRequestState, action: IRequestActions) => {
      switch (action.type) {
        case REQUEST_PREFIX + scope.toUpperCase() + RUNNING_SUFFIX:
          return {
            // take the current state and manipulate isLoading and loadingError
            ...state,
            isLoading: !(action as IUsecaseRequestRunningAction).error,
            loadingError: (action as IUsecaseRequestRunningAction).error,
          }

        case REQUEST_PREFIX + scope.toUpperCase() + SUCCESS_SUFFIX:
          return {
            // take the current state and manipulate isLoading: false (no longer loading) and loadingError: null (no errors)
            ...state,
            isLoading: false,
            loadingError: null,
          }

        default:
          return state
      }
    }

  return requestReducer
}

/**
 * This Reducer is a general Reducer for all Entity-Types and collections of those Entity-Types, when requesting
 * data of them from the API.
 *
 * It only handles model-SUCCESS-Actions, triggered by the (general)saga(s), after the request to the API was
 * successfull and the (new/updated) or loaded entity should be stored in the state or should be deleted from
 * the state. It does not handle request-success-actions!
 *
 * Is used in redux/reducer/data.ts
 *
 * @param entityType The EntityType to be handled.
 * @returns An updated Entity or an updated collection of those Entities after Update, Delete or collection-loading
 */
export const scopedObjectReducer = <T extends INumericIdentifierModel>(entityType: EntityType): (state: IIndexedCollectionState<T>, action: IEntityApiRequestSuccessAction) => IIndexedCollectionState<T> => {
  const objectReducer = (
    state: IIndexedCollectionState<T> = initialIndexedCollectionState as IIndexedCollectionState<T>,
    action: IEntityApiRequestSuccessAction,
  ) => {
    // first check if we're responsible; if action has an other entityType than what we're supposed to react on, skip
    if (entityType !== action.entityType) {
      return state
    }

    switch (action.type) {
      case ActionTypes.LoadSuccess:
      // no break
      case ActionTypes.CreateSuccess:
      // no break
      case ActionTypes.UpdateSuccess:
        const singleModel = (action as IModelCLUDOperationSuccessAction<T>).model
        return { ...state, [singleModel.id]: singleModel }

      case ActionTypes.DeleteSuccess:
        const deletedModel = (action as IModelCLUDOperationSuccessAction<T>).model
        const reduced: IIndexedCollectionState<T> = {}
        Object.values(state).forEach((value: T) => {
          if (value.id === deletedModel.id) { return }
          reduced[value.id] = value
        })
        return reduced

      case ActionTypes.LoadCollectionSuccess:
        const newCollection: IIndexedCollectionState<T> = {}
        const data = (action as ILoadCollectionSuccessAction<T>).collection
        data["hydra:member"].forEach((element: T) => {
          newCollection[element.id] = element
        })
        return { ...state, ...newCollection }

      // necessary, because all reducers are called
      // => if an action is triggered, where this reducer is not responsible, ignore this action and return the previous state
      default:
        return state
    }
  }

  return objectReducer
}

// type definitions exist b/c they are mentioned twice: IFilteredCollectionState and emptyGetItemsFunction/emptyGetLoadNextPageAction
type GetItemsFunctionType = <T extends INumericIdentifierModel>() => T[]
type GetLoadNextPageActionFunctionType = () => ILoadCollectionPageAction

/**
 * State to handle states for different entity collections,
 * that are loaded, updated, deleted and so on
 */
export interface IFilteredCollectionState extends IRequestState { // export for type precision casting
  /** IDs of the items that are handled by that state */
  itemIds: number[]
  /** number of totalItems: delivered from the API. Useful to see, how many items are not already fetched. */
  totalItems: number
  /** Link to fetch the next page, constructed by the API from the first-page-filtercriteria. */
  nextLink: string
  /** has (at least) the first page been loaded? */
  loaded: boolean
  /**
   * Helper function to get the entities from the data store.
   * Function will be connected within the selector selectCollectionUsecaseState
   *
   */
  getItems: GetItemsFunctionType
  /**
   * Helper function to get the action to load the next page.
   * Function will be connected within the selector selectCollectionUsecaseState
   *
   */
  getLoadNextPageAction: GetLoadNextPageActionFunctionType
}

// We need those objects to allow tests with emptyFilteredCollectionState
// which would otherwise fail: expect({x:()=>[]}).toStrictEqual({x:()=>[]}) evaluates to false
// b/c at runtime, copying an arrow function (in ...emptyFilteredCollectionState statements)
// creates a new object that is not strict equal.
const emptyGetItemsFunction: GetItemsFunctionType = () => []
const emptyGetLoadNextPageAction: GetLoadNextPageActionFunctionType = () => null

export const emptyFilteredCollectionState: IFilteredCollectionState = {
  isLoading: false,
  loadingError: null,
  itemIds: [],
  totalItems: 0,
  nextLink: null,
  loaded: false, // not loaded yet!
  getItems: emptyGetItemsFunction,
  getLoadNextPageAction: emptyGetLoadNextPageAction,
}

interface IFilteredCollectionPerUsecaseState {
  [usecaseKey: string]: IFilteredCollectionState
}

interface ISingleEntityPerUsecaseState {
  [usecaseKey: string]: IRequestState
}

// NOTE "export" only for tests/redux/helper/reducers.spec.ts initial state creation
export interface IEntityUsecaseState {
  singleEntities: ISingleEntityPerUsecaseState
  filteredCollections: IFilteredCollectionPerUsecaseState
}

// NOTE "export" only for tests/pages/MyProjectsDashboard.spec.tsx initial state creation
export type IEntityUsecaseStates = {
  [key in EntityType as string]: IEntityUsecaseState
}

interface IStatisticsSingleState<T extends IStatistics> extends IRequestState {
  // we cannot "merge" the data directly into IRequestState by extending the state with IStatistics
  // to access the properties directly, because there may be property name conflicts,
  // if a statistic property is named the same like an IRequestState property
  /** statistics data */
  data: T
}

/**
 * States for Statistics
 */
export type IStatisticsStates = {
  [key in StatisticsType]: IStatisticsState<key>
}

/**
 * Define a statistic state in dependence of the given StatisticType.
 */
export type IStatisticsState<SType extends StatisticsType> = IStatisticsSingleState<IDerivedStatistics<SType>>

export const emptyEntityTypeState: IEntityUsecaseState = {
  singleEntities: {},
  filteredCollections: {}
}

/**
 * empty IEntityUsecaseStates for initial states
 * NOTE "export" only for tests/pages/MyProjectsDashboard.spec.tsx initial state creation
 */
export const emptyEntityTypeStates: IEntityUsecaseStates = {}

/**
 * This reducer manages the complex entity-aware usecase-specific request states.
 * The state has the following structure:
 * [entityType]: {
 * ..[usecaseKey]: singleEntities state (array-of: CLUD of a single entity model object)
 * ..[usecaseKey]: filteredCollectionLoading state (array-of: load of a filtered collection)
 * }
 *
 * @param entityUsecaseStates the IEntityUsecaseStates object, as in AppState.entityUsecases
 * @param action an action after an api call
 * @returns a state
 */
export const entityUsecaseReducer = (
  entityUsecaseStates: IEntityUsecaseStates = emptyEntityTypeStates,
  action: INewUsecaseRequestSuccessAction
    | INewUsecaseRequestRunningAction
    | IModelCLUDOperationSuccessAction<INumericIdentifierModel>
): IEntityUsecaseStates => {

  switch (action.type) {

    /// /////////////////
    // first block: care for all REQUEST (running or success) signals that may affect some of IEntityUsecaseState's sub-states

    // on UsecaseRequestRunning we just store the isLoading and maybe an loading error
    case ActionTypes.NewUsecaseRequestRunning:
      const usecaseRequestRunningAction = action
      const entityTypeForRunning = usecaseRequestRunningAction.entityType

      if (usecaseRequestRunningAction.isCollection) {
        // we got a Collection "request running" signal

        return {
          ...entityUsecaseStates,
          [entityTypeForRunning]: {
            ...emptyEntityTypeState,
            ...entityUsecaseStates[entityTypeForRunning],
            filteredCollections: {
              ...entityUsecaseStates[entityTypeForRunning]?.filteredCollections,
              [usecaseRequestRunningAction.usecaseKey]: {
                ...emptyFilteredCollectionState, // use empty state to initialize with default values, will be overwritten by next lines
                // @todo: check this line: https://futureprojects.atlassian.net/browse/FCP-1304
                ...entityUsecaseStates[entityTypeForRunning]?.filteredCollections?.[usecaseRequestRunningAction.usecaseKey],
                isLoading: !usecaseRequestRunningAction.error,
                loadingError: usecaseRequestRunningAction.error,
                loaded: false,
              } as IFilteredCollectionState
            }
          } as IEntityUsecaseState
        } as IEntityUsecaseStates
      } else {
        // we got a SingleModel/Entity "request running" signal
        return {
          ...entityUsecaseStates,
          [entityTypeForRunning]: {
            ...emptyEntityTypeState,
            ...entityUsecaseStates[entityTypeForRunning],
            singleEntities: {
              ...entityUsecaseStates[entityTypeForRunning]?.singleEntities,
              [usecaseRequestRunningAction.usecaseKey]: {
                ...initialRequestState, // use empty state to initialize with default values, will be overwritten by next lines
                // @todo: check this line: https://futureprojects.atlassian.net/browse/FCP-1304
                ...entityUsecaseStates[entityTypeForRunning]?.singleEntities?.[usecaseRequestRunningAction.usecaseKey],
                isLoading: !usecaseRequestRunningAction.error,
                loadingError: usecaseRequestRunningAction.error,
                loaded: false,
              } // as ISingleEntityState
            }
          } as IEntityUsecaseState
        } as IEntityUsecaseStates
      }

    // on UsecaseRequestSuccess we update the isLoading and error with null/empty, and care for loaded states,
    // and in case of Collections, we store our itemIds list.
    case ActionTypes.NewUsecaseRequestSuccess:
      const usecaseRequestSuccessAction = action
      const entityTypeForSuccess = usecaseRequestSuccessAction.entityType

      if (usecaseRequestSuccessAction.isCollection) {
        // we got a Collection "request success" signal

        const filteredCollectionUsecaseRequestSuccessAction = usecaseRequestSuccessAction as NewFilteredCollectionUsecaseRequestSuccessAction

        const items = filteredCollectionUsecaseRequestSuccessAction.result as IHydraCollection<INumericIdentifierModel>
        const loadedItemIds = items["hydra:member"].map((f) => f.id)
        // next line for convenience
        const oldStateItemIds = entityUsecaseStates[entityTypeForSuccess]?.filteredCollections?.[usecaseRequestSuccessAction.usecaseKey]?.itemIds

        const mergedItemIds = filteredCollectionUsecaseRequestSuccessAction.isPage
          ? oldStateItemIds?.concat(loadedItemIds.filter((item) => oldStateItemIds.indexOf(item) < 0)) // concenate both arrays and filter out duplicates
          : loadedItemIds

        return {
          ...entityUsecaseStates,
          [entityTypeForSuccess]: {
            ...entityUsecaseStates[entityTypeForSuccess],
            filteredCollections: {
              ...entityUsecaseStates[entityTypeForSuccess]?.filteredCollections,
              [usecaseRequestSuccessAction.usecaseKey]: {
                ...emptyFilteredCollectionState, // use empty state to initialize with default values, will be overwritten by next lines
                // @todo: check this line: https://futureprojects.atlassian.net/browse/FCP-1304
                ...entityUsecaseStates[entityTypeForSuccess]?.filteredCollections?.[usecaseRequestSuccessAction.usecaseKey],
                itemIds: mergedItemIds,
                totalItems: items["hydra:totalItems"],
                nextLink: items["hydra:view"] && items["hydra:view"]["hydra:next"],
                ...REQUEST_STATE_SUCCESSFUL,
              } as IFilteredCollectionState
            }
          } as IEntityUsecaseState
        } as IEntityUsecaseStates
      } else {
        // we got a SingleModel/Entity "request success" signal

        return {
          ...entityUsecaseStates,
          [entityTypeForSuccess]: {
            ...entityUsecaseStates[entityTypeForSuccess],
            singleEntities: {
              ...entityUsecaseStates[entityTypeForSuccess]?.singleEntities,
              [usecaseRequestSuccessAction.usecaseKey]: {
                ...initialRequestState, // use empty state to initialize with default values, will be overwritten by next lines
                // @todo: check this line: https://futureprojects.atlassian.net/browse/FCP-1304
                ...entityUsecaseStates[entityTypeForSuccess]?.singleEntities?.[usecaseRequestSuccessAction.usecaseKey],
                ...REQUEST_STATE_SUCCESSFUL,
              } // as ISingleEntityState
            }
          } as IEntityUsecaseState
        } as IEntityUsecaseStates
      }

    /// /////////////////
    // second block: care for all CUD ModelOperationSuccess signals that may affect the IEntityUsecaseState's sub-states "filteredCollection"
    // NOTE unlike in the first block, we do NOT have to make sure that our usecase entry exists

    // on CREATE and UPDATE model operation SUCCESS signals, we'll clear ALL filteredCollections of the usecases of this entity
    case ActionTypes.CreateSuccess:
    case ActionTypes.UpdateSuccess:
      const modelCLUDOperationSuccessAction = action

      const emptyUsecasesStates = {} as IFilteredCollectionPerUsecaseState
      // eslint-disable-next-line guard-for-in
      for (const usecaseKey in entityUsecaseStates[modelCLUDOperationSuccessAction.entityType]?.filteredCollections) {
        emptyUsecasesStates[usecaseKey] = emptyFilteredCollectionState
      }

      return {
        ...entityUsecaseStates,
        [modelCLUDOperationSuccessAction.entityType]: {
          ...entityUsecaseStates[modelCLUDOperationSuccessAction.entityType],
          filteredCollections: emptyUsecasesStates
        }
      }
    // on DELETE model operation SUCCESS signals, we'll remove this model.id from ALL filteredCollections of this entity
    case ActionTypes.DeleteSuccess:
      const modelDeleteOperationSuccessAction = action

      const reducedUsecasesStates = {} as IFilteredCollectionPerUsecaseState
      // eslint-disable-next-line guard-for-in
      for (const usecaseKey in entityUsecaseStates[modelDeleteOperationSuccessAction.entityType]?.filteredCollections) {
        // next line for convenience
        const usecaseCollectionForDeleteState = entityUsecaseStates[modelDeleteOperationSuccessAction.entityType].filteredCollections[usecaseKey]

        const deletedModel = modelDeleteOperationSuccessAction.model
        const contains = usecaseCollectionForDeleteState.itemIds.includes(deletedModel.id)

        reducedUsecasesStates[usecaseKey] = contains
          ? {
            ...usecaseCollectionForDeleteState,
            itemIds: usecaseCollectionForDeleteState.itemIds.filter(id => id !== deletedModel.id),
            totalItems: usecaseCollectionForDeleteState.totalItems - 1,
          }
          : usecaseCollectionForDeleteState
      }

      return {
        ...entityUsecaseStates,
        [modelDeleteOperationSuccessAction.entityType]: {
          ...entityUsecaseStates[modelDeleteOperationSuccessAction.entityType],
          filteredCollections: reducedUsecasesStates,
        }
      }

  }

  return entityUsecaseStates
}

/**
 * empty IStatisticsStates for initial states
 * NOTE "export" only for TestState class
 */
export const emptyStatisticsStates: IStatisticsStates = {
  [GlobalStatisticsType.Challenge]: null,
  [GlobalStatisticsType.Ideas]: null,
  [GlobalStatisticsType.Program]: null,
  [GlobalStatisticsType.Project]: null,
  [GlobalStatisticsType.Tenant]: null,
  [GlobalStatisticsType.User]: null,
  [SingleStatisticsType.Program]: null
}


/**
 * This reducer manages the statistics states
 * The state has the following structure:
 * [statisticsType]: IStatistics
 *
 * @param statisticsStates the IStatisticsStates object, as in AppState.statistics
 * @param action an INewUsecaseRequestSuccessAction after an api call
 * @returns a state
 */
export const statisticsReducer = (statisticsStates: IStatisticsStates = emptyStatisticsStates, action: StatisticRequestActions): IStatisticsStates => {

  switch (action.type) {
    // on LoadStatisticsRequestRunning the request state is stored
    case ActionTypes.LoadStatisticsRunning:
      const requestAction = action
      return {
        ...statisticsStates,
        [requestAction.usecaseKey]: {
          // update request data
          isLoading: !requestAction.error,
          loadingError: requestAction.error,
          loaded: false,
          // reset recent statistics data
          data: null,
        } as IStatisticsSingleState<IStatistics>
      }
    // on LoadStatisticsSuccess the incoming results are stored
    // and the request is marked as finished and successful
    case ActionTypes.LoadStatisticsSuccess:
      const successAction = action
      return {
        ...statisticsStates,
        [successAction.usecaseKey]: {
          // on success we expect: loading is finished, no error occured, data has been loaded
          ...REQUEST_STATE_SUCCESSFUL,
          // add received data
          data: successAction.result as IStatistics
        } as IStatisticsSingleState<IStatistics>
      }
  }

  return statisticsStates
}