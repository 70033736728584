
import { IOIDCAPI } from "@modules/oidc/src"
import { IOIDCProvider } from "@modules/oidc/src/models/IOIDCProvider"
import { captureException } from "@sentry/nextjs"

import { HydraClient } from "@api/hydraClient"
import {
  ChallengeTransitionState,
  // IAttachmentDefinition,
  IAuthReply,
  IChallenge,
  // IChallengeConcretization,
  IContactEmail,
  ICredentials,
  IEmailChange,
  IFeedbackInvitation,
  IFeedbackPost,
  IFundApplication,
  IHydraCollection,
  IModel,
  INativeHydraCollection,
  INewPasswordRequest,
  INumericIdentifierModel,
  IOperationResultOutput,
  IPasswordChange,
  IPasswordReset,
  IPasswordResetRequest,
  IProgram,
  IProject,
  IProjectCreation,
  IProjectReport,
  IProposal,
  IProposalAttachment,
  IRI,
  IRIstub,
  ISupportRequest,
  ISysinfo,
  ITeamEmail,
  ITeamUpload,
  IUser,
  IUserEmail,
  IUserObjectRole,
  IVerification,
  ProjectState,
  ProposalTransitionState,
  SubResourceUrl,
  SubResourceUrlPattern,
  TransitionInput
} from "@api/schema"
import { CreateableEntityType, DeleteableEntityType, EntityType, GlobalStatisticsType, LoadableEntityType, SingleStatisticsType, StatisticsType, UpdateableEntityType, UploadType } from "@redux/reduxTypes"
import { entityTypeFromIModelOrIRI, idFromIModelOrIRI, iriFromIModelOrIRI } from "@services/util"
import { FCP_API_ENTRYPOINT } from "config"

import { IStatistics } from "./schema/statistics"
import { IUserWriteDTO } from "./schema-dto"


// #region types and interface definitions

// könnte helfen, auf anwendungscoder-ebene Dinge leichter zu verstehen, aber nötig ist es nicht
// könnte frühere Prüfung erlauben, ob ein sub-endpunkt überhaupt existiert
// enum ApiSubCollectionUsecases {
//   ChallengesOfProgram,
//   ChallengesOfProvider,
//   CategoriesOfProgram,
//   IdeasOfProgram,
//   IdeasOfUser,
//   ProgramsOfProvider,
//   RelatedProgramsOfChallenges,
//   ProjectsOfProgram,
//   ProjectsOfUser,
//   RolesOfUser, // "my UserObjectRoles"
//   MembershipsOfProject,
//   ManagersOfTenant,
//   ManagersOfProgram,
//   ManagersOfChallenge,
// }

// type ApiUsecaseEndpointUrl = `/${string}/[id]/${string}`

// /**
//  * this data defines an endpoint
//  */
// interface ApiUsecaseEndpoint {
//   /**
//    * url must not end with a slash
//    */
//   url: ApiUsecaseEndpointUrl
//   /** entity type that is returned from that endpoint */
//   entityType: EntityType
//   specialHandling?: string // @todo: Anweisungen darüber, wie der Reducer diesen Typ behandeln soll, könnte aber auch schlicht im Reducer definiert werden!
// }

// /**
//  * an endpoint for a specific entity is defined as
//  * EntityType => Endpoint
//  */
// export type ApiUsecaseEndpointList = {
//   [key in ApiSubCollectionUsecases]: ApiUsecaseEndpoint
// }

// /**
//  * list of all api usecase endpoints that deliver collections of entities that are connected to the "parent" entity
//  */
// export const apiUsecaseEndpointList: ApiUsecaseEndpointList = {
//   [ApiSubCollectionUsecases.CategoriesOfProgram]: {
//     url: "/programs/[id]/categories",
//     entityType: EntityType.Category
//   },
//   [ApiSubCollectionUsecases.ChallengesOfProgram]: {
//     url: "/programs/[id]/related_challenges",
//     entityType: EntityType.Challenge
//   },
//   [ApiSubCollectionUsecases.ChallengesOfProvider]: {
//     url: "/providers/[id]/challenges",
//     entityType: EntityType.Challenge
//   },
//   [ApiSubCollectionUsecases.IdeasOfProgram]: {
//     url: "/programs/[id]/ideas",
//     entityType: EntityType.Project // @todo: EntityType.Ideas!!
//   },
//   [ApiSubCollectionUsecases.IdeasOfUser]: {
//     url: "/users/[id]/ideas",
//     entityType: EntityType.Project // @todo: EntityType.Ideas!!
//   },
//   [ApiSubCollectionUsecases.ManagersOfChallenge]: {
//     url: "/challenges/[id]/managers",
//     entityType: EntityType.UserObjectRole
//   },
//   [ApiSubCollectionUsecases.ManagersOfProgram]: {
//     url: "/programs/[id]/managers",
//     entityType: EntityType.UserObjectRole
//   },
//   [ApiSubCollectionUsecases.ManagersOfTenant]: {
//     url: "/tenants/[id]/managers",
//     entityType: EntityType.UserObjectRole
//   },
//   [ApiSubCollectionUsecases.MembershipsOfProject]: {
//     url: "/projects/[id]/memberships",
//     entityType: EntityType.UserObjectRole
//   },
//   [ApiSubCollectionUsecases.ProgramsOfProvider]: {
//     url: "/providers/[id]/programs",
//     entityType: EntityType.Program
//   },
//   [ApiSubCollectionUsecases.ProjectsOfProgram]: {
//     url: "/programs/[id]/projects",
//     entityType: EntityType.Project
//   },
//   [ApiSubCollectionUsecases.ProjectsOfUser]: {
//     url: "/users/[id]/projects",
//     entityType: EntityType.Project
//   },
//   [ApiSubCollectionUsecases.RelatedProgramsOfChallenges]: {
//     url: "/challenges/[id]/related_programs",
//     entityType: EntityType.Program
//   },
//   [ApiSubCollectionUsecases.RolesOfUser]: {
//     url: "/users/[id]/object_roles",
//     entityType: EntityType.UserObjectRole
//   },
// }

/**
 * type of an endpoint that is implemented as sub resource of a entity endpoint by the backend API
 */
interface SubResourceEndpoint {
  /**
   * URL of the sub endpoint, e.g. "/projects/[id]/memberships".
   * This allows also completely different endpoint targets, different from the url of the base entity.
   */
  url: SubResourceUrlPattern
  /**
   * The API omits (mostly/always?) the property that contains the parent entity IRI.
   * To have full information this property name is deposited here.
   *
   * Example: UserObjectRoles fetched from the users sub resource endpoint are missing the "user" prop.
   * So "user" should be put here.
   *
   * NOTE: this is for information purposes only (24.04.2024)
   */
  nameOfOmittedParentProperty?: string
}


/**
 * type of an array of sub resources under a parent entity point
 */
type SubResourceList = {
  [key in EntityType]?: SubResourceEndpoint
}

/**
 * this data defines an endpoint
 */
export interface Endpoint {
  /**
   * Url of an endpoint also matches the IRI stub of an entity.
   * Url must not end with a slash.
   */
  url: IRIstub
  /**
   * context string: what the API delivers as HydraCollection "@context"
   */
  "@context"?: string
}

export interface EntityEndpoint extends Endpoint {
  /**
   * optional sub resources that may be fetched from sub-urls of this endpoint
   * or used to trigger actions
   */
  subResource?: SubResourceList
}

/**
 * an endpoint for a specific entity is defined as
 * EntityType => Endpoint
 */
export type EntityEndpointList = {
  [key in EntityType]: EntityEndpoint
}

/**
 * an endpoint for a specific statistic is defined as
 * StatisticsType => Endpoint
 */
export type StatisticsEndpointList = {
  [key in StatisticsType]: IRIstub | SubResourceUrlPattern
}

/**
 * an endpoint for a upload is defined as
 * UploadType => Endpoint
 */
export type UploadEndpointList = {
  [key in UploadType]: Endpoint
}

// #endregion

// #region endpoint definitions

/**
 * This list contains all entity-specific endpoints
 * to be used in the generic functions to create, update, delete, load single elementes and collections
 * of an entity.
 *
 * Every url is identical with the central part of an "@id" of an IModel, called IRI.
 * So with this list it is also possible to calculate the EntityType from an IRI, done in
 * entitytypeFromIri()
 */
export const entityEndpointList: EntityEndpointList = {
  [EntityType.AttachmentDefinition]: {
    url: "/attachment_definitions",
    "@context": "/contexts/AttachmentDefinition",
  },
  [EntityType.Category]: {
    // this url is only used, when a category needs to be updated or deleted
    url: "/categories",
    "@context": "/contexts/Category",
  },
  [EntityType.Challenge]: {
    url: "/challenges",
    "@context": "/contexts/Challenge",
    subResource: {
      [EntityType.Program]: {
        url: "/challenges/[id]/related_programs"
      },
      [EntityType.UserObjectRole]: {
        url: "/challenges/[id]/managers"
      }
    }
  },
  [EntityType.ChallengeConcretization]: {
    url: "/challenge_concretizations",
    "@context": "/contexts/ChallengeConcretization",
  },
  [EntityType.Discussion]: {
    url: "/discussions",
    "@context": "/contexts/Discussion",
    subResource: {
      [EntityType.FeedbackPost]: {
        url: "/discussions/[id]/posts"
      }
    }
  },
  [EntityType.FeedbackInvitation]: {
    url: "/feedback_invitations",
    "@context": "/contexts/FeedbackInvitation",
  },
  [EntityType.FeedbackPost]: {
    // @todo multi: an diesem Endpunkt können keine Feedbackposts gefetched werden!
    // Das ist eine reine IRI-Angabe!
    url: "/feedback_posts",
    "@context": "/contexts/FeedbackPost",
    subResource: {
      // reply to an existing feedbackpost
      [EntityType.FeedbackPost]: {
        url: "/feedback_posts/[id]/reply"
      }
    }
  },
  [EntityType.Idea]: {
    url: "/ideas",
    "@context": "/contexts/Idea",
  },
  [EntityType.Program]: {
    url: "/programs",
    "@context": "/contexts/Program",
    subResource: {
      [EntityType.Category]: {
        // used to create a category or get all categories of a program
        url: "/programs/[id]/categories"
      },
      [EntityType.Challenge]: {
        url: "/programs/[id]/related_challenges"
      },
      [EntityType.Idea]: {
        url: "/programs/[id]/ideas",
      },
      [EntityType.Project]: {
        url: "/programs/[id]/projects"
      },
      [EntityType.UserObjectRole]: {
        url: "/programs/[id]/managers",
        nameOfOmittedParentProperty: "program"
      },
    }
  },
  [EntityType.Project]: {
    url: "/projects",
    "@context": "/contexts/Project",
    subResource: {
      [EntityType.Discussion]: {
        url: "/projects/[id]/discussions"
      },
      [EntityType.Proposal]: {
        url: "/projects/[id]/proposals"
      },
      // create a team upload and get collection of team uploads
      [EntityType.TeamUpload]: {
        url: "/projects/[id]/team_uploads",
      },
      [EntityType.UserObjectRole]: {
        url: "/projects/[id]/memberships"
      }
    }
  },
  [EntityType.ProjectFollowership]: {
    url: "/project_followerships",
    "@context": "/contexts/ProjectFollowership",
  },
  [EntityType.ProjectMembership]: {
    url: "/project_memberships",
    "@context": "/contexts/ProjectMembership",
  },
  [EntityType.Proposal]: {
    url: "/proposals",
    "@context": "/contexts/Proposal",
  },
  [EntityType.ProposalAttachment]: {
    url: "/proposal_attachments",
    "@context": "/contexts/ProposalAttachment",
  },
  [EntityType.Provider]: {
    url: "/providers",
    subResource: {
      [EntityType.Program]: {
        url: "/providers/[id]/programs",
      },
      [EntityType.Challenge]: {
        url: "/providers/[id]/challenges"
      },
      [EntityType.UserObjectRole]: {
        url: "/providers/[id]/managers",
      },
    }
  },
  [EntityType.SupportRequest]: {
    url: "/support_requests",
    "@context": "/contexts/SupportRequest",
  },
  // only for edit and delete a team upload
  [EntityType.TeamUpload]: {
    url: "/team_uploads",
    "@context": "/contexts/TeamUpload",
  },
  [EntityType.User]: {
    url: "/users",
    "@context": "/contexts/User",
    subResource: {
      // the discussions of the user, created by himself
      // @todo Prüfen, ob dies wirklich nur die von ihm kreierten Diskussions sind, oder auch die, an denen er teilnimmt
      // @todo: es gibt einen zweiten Endpunkt, der Discussions liefert: /users/[id]/discussions:
      // die Diskussions ÜBER den User <- die entityEndpointList erlaubt es aber nicht, mehrere solcher Typen anzugeben
      // -> brauchen wir also doch eine eher usecase-orientierte entityEndpointList statt einer EntityTyp-orientierten Liste?
      [EntityType.Discussion]: {
        url: "/users/[id]/created-discussions"
      },
      [EntityType.UserObjectRole]: {
        url: "/users/[id]/object_roles",
        nameOfOmittedParentProperty: "user"
      },
      [EntityType.Idea]: {
        url: "/users/[id]/ideas",
      },
      [EntityType.Project]: {
        url: "/users/[id]/projects",
      },
    }
  },
  [EntityType.UserObjectRole]: {
    // @todo multi: or set this to NULL, since it is neither a true endpoint nor an entity IRI prefix? (same for Category)
    // This should be probably completely deleted, because it is a subressource. Therefor the EntityEndpointList has to be redefined:
    // export type EntityEndpointList = {
    //  [key in Exclude<EntityType, EntityType.UserObjectRole>]: Endpoint
    // }
    url: "/object_roles"
  }
}


/**
 * This list contains all statistic-specific endpoints
 * to be used in the generic functions to load them.
 */
export const statisticsEndpointList: StatisticsEndpointList = {
  [GlobalStatisticsType.Challenge]: "/challenges/statistics",
  [GlobalStatisticsType.Ideas]: "/ideas/statistics",
  [GlobalStatisticsType.Program]: "/programs/statistics",
  [SingleStatisticsType.Program]: "/programs/[id]/statistics",
  [GlobalStatisticsType.Project]: "/projects/statistics",
  [GlobalStatisticsType.Tenant]: "/tenants/statistics",
  [GlobalStatisticsType.User]: "/users/statistics",
}


/**
 * This list contains most upload-specific endpoints
 * to be used in the generic functions to upload (create), update or delete a file.
 *
 * Because a file is usually bound to a specific entity that is identified by its id
 * the URL usually uses [id] as placeholder to be replaced by the real id by the upload function.
 */
const uploadEndpointList: UploadEndpointList = {
  [UploadType.ConcretizationImage]: {
    url: "/challenge_concretizations/[id]/image",
  },
  [UploadType.ChallengeLogo]: {
    url: "/challenges/[id]/logo",
  },
  [UploadType.ProcessLogo]: {
    url: "/programs/[id]/logo",
  },
  [UploadType.ProjectPicture]: {
    url: "/projects/[id]/picture",
  },
  [UploadType.ProjectVisualization]: {
    url: "/projects/[id]/visualization",
  },
  [UploadType.UserPicture]: {
    url: "/users/[id]/picture",
  }
}
// #endregion


/**
 * replaces the [id] placeholder element within an IRIstub by the given id
 *
 * @param url an IRIstub/endpoint URL
 * @param id id to be inserted instead the [id] placeholder
 * @returns the IRIstub with the concrete id instead of the placeholder
 */
export const replaceIdPlaceholder = (url: IRIstub, id: number | string): IRIstub =>
  url?.replace("[id]", id?.toString()) as IRIstub

/**
 * @param url an IRIstub/endpoint URL
 * @returns true, if the given url includes an [id] placeholder
 */
export const hasIdPlaceholder = (url: IRIstub): boolean =>
  url && url.indexOf("[id]") >= 0

/**
 * This class provides functions for the communication with the backend-API
 * based on HydraClient, that uses Axios for fetching and sending data to servers.
 * All relevant endpoints are addressed here.
 *
 * It implements API interfaces from different modules.
 */
export class ProjektfabrikClient extends HydraClient implements IOIDCAPI<IAuthReply, INativeHydraCollection<IOIDCProvider>> {

  // #region generalEntityFunctions

  /**
   * General function to load one Entity from the backend api by id.
   * Entity-specific endpoint must be defined in the entityEndpointList.
   *
   * @param entityType entitytype that should be loaded
   * @param slugOrId id or slug of the entity that should be loaded
   * @returns the entity
   */
  public loadSingleEntity = <T extends INumericIdentifierModel>(entityType: LoadableEntityType, slugOrId: number | string): Promise<T> => {
    // get the endpoint definition from the entityEndpointList
    const endpoint = entityEndpointList[entityType]

    // start the request and return results
    return this.get(endpoint.url + "/" + slugOrId.toString()) as Promise<T>
  }

  /**
   * General function to load the first page of a collection of entities from the backend api.
   * Entity-specific endpoint must be defined in the entityEndpointList.
   *
   * The result returns the next page url in the IHydraCollection["hydra:view"]["hydra:next"]
   * This url should be passed to the get-function to fetch its content.
   *
   * @todo: müßte diese Funktion korrekterweise heißen loadEntityCollectionFirstPage? Der Abruf von Folge-Pages erfolgt direkt über die .get-Funktion
   *
   * @param entityType entitytype that should be loaded
   * @param query list of criteria for the entities that should be loaded
   * @param parentIri IRI of the parent entity in case of loading the collection from a subresource of the parent's endpoint
   * @returns a collection of entities
   */
  public loadEntityCollection = <T extends INumericIdentifierModel>(entityType: LoadableEntityType, query: Record<string, unknown>, parentIri: IRI): Promise<IHydraCollection<T>> => {
    // get the endpoint definition from the entityEndpointList
    const endpointUrl = ProjektfabrikClient.getEndpointUrl(entityType, parentIri)

    // start the request and return results
    return this.get(endpointUrl, query) as Promise<IHydraCollection<T>>
  }

  /**
   * General function to create an entity of a given type via the backend api.
   *
   * @param entityType has to be a CreateableEntityType
   * @param entity the entity to be created
   * @returns the created entity
   */
  public createEntity = <T extends INumericIdentifierModel>(entityType: CreateableEntityType, entity: INumericIdentifierModel, parentIri?: IRI): Promise<T> => {
    // special use cases
    switch (entityType) {
      // ProposalAttachment is a file and properties, so it has to be handled as upload
      case EntityType.ProposalAttachment:
        // upload transaction calls this.upload instead of this.post
        return this.createProposalAttachment(entity) as Promise<T>
      // TeamUpload is a file and properties, so it has to be handled as upload
      case EntityType.TeamUpload:
        return this.createTeamUpload(entity, parentIri) as Promise<T>
      case EntityType.FeedbackPost:
        // replying to an existing FeedbackPost by calling a special endpoint
        return this.replyFeedbackPost(entity, parentIri) as Promise<T>
    }

    const endpointUrl = ProjektfabrikClient.getEndpointUrl(entityType, parentIri)

    // start the request
    return this.post(endpointUrl, entity) as Promise<T>
  }


  /**
   * General function to update a given entity via the backend api.
   * Entity-specific endpoint is encoded in the IRI/@id of every entity
   *
   * @param entity the entity to be updated
   * @returns the updated entity
   */
  public updateEntity = <T extends INumericIdentifierModel>(entity: INumericIdentifierModel): Promise<T> => {
    // special use cases
    switch (entityTypeFromIModelOrIRI<UpdateableEntityType>(entity)) {
      // ProposalAttachment is a file and properties, so it has to be handled as upload
      case EntityType.ProposalAttachment:
        return this.updateProposalAttachment(entity) as Promise<T>
      // TeamUpload is a file and properties, so it has to be handled as upload
      case EntityType.TeamUpload:
        return this.updateTeamUpload(entity) as Promise<T>
      case EntityType.Project:
        // don't try to set the state to "inactive" (even when it is already inactive),
        // as this is forbidden by the API and would cause a validation error (for PM, admin, coordinator)
        if ((entity as IProject).state && (entity as IProject).state === ProjectState.Inactive) {
          delete (entity as IProject).state
        }
      // no break
    }

    // start the request: endpoint is encoded in the IRI/@id of every entity
    return this.patch(entity["@id"], entity) as Promise<T>
  }


  /**
   * General function to delete a given entity via the backend api.
   * Entity-specific endpoint is encoded in the IRI/@id of every entity
   *
   * @param entity the entity to be deleted
   * @returns nothing after deleting the entity
   */
  public deleteEntity = (entity: INumericIdentifierModel): Promise<void> => {
    // special use cases
    switch (entityTypeFromIModelOrIRI<DeleteableEntityType>(entity)) {
      // FeedbackPosts are not deleted, but its content to keep the discussion chain
      // therefor a special endpoint is used
      case EntityType.FeedbackPost:
        return this.deleteFeedbackPostContent(entity as IFeedbackPost)
    }

    // start the request: endpoint is encoded in the IRI/@id of every entity
    return this.delete(entity["@id"]) as Promise<void>
  }

  /**
   * General function to upload a file corresponding to an entity to a given endpoint
   *
   * @param uploadType the type of upload that should be performed
   * @param entity the entity to which the upload is connected
   * @param file the file that should be uploaded
   * @returns a Promise of the (changed) entity
   */
  public uploadFile = <T extends INumericIdentifierModel>(uploadType: UploadType, entity: INumericIdentifierModel, file: File): Promise<T> => {
    const formData = new FormData()
    formData.append("file", file)
    // use the uploadType-corresponding endpoint url and replace the placeholder [id] by the actual .id of the entity
    const url = replaceIdPlaceholder(uploadEndpointList[uploadType]?.url, entity?.id)
    return this.upload(url, file ? formData : null) as Promise<T>
  }

  // #endregion

  // #region general statistics function

  /**
   * General function to load a global or a single statistic.
   * statistics-specific endpoint must be defined in the statisticsEndpointList.
   *
   * @param statisticsType type of statistics that should be loaded
   * @returns the statistics
   */
  public loadStatistics = <T extends IStatistics>(statisticsType: StatisticsType, id?: number): Promise<T> => {
    // get the endpoint definition from the statisticsEndpointList
    let endpointUrl = statisticsEndpointList[statisticsType]
    if (id) {
      endpointUrl = statisticsEndpointList[statisticsType].replace(
        "[id]", id.toString()
      ) as SubResourceUrlPattern
    }

    // start the request and return results
    return this.get(endpointUrl) as Promise<T>
  }

  /**
   * Getting the endpoint url the entityType. If a parentIri is given, returns the SubResourceUrl
   *
   * @throws an Error, if the SubEndPoint could not be found with the given parameters.
   * @param entityType
   * @param parentIri
   * @returns SubRessourceUrl
   */
  public static getEndpointUrl = (entityType: EntityType, parentIri?: IRI): IRIstub | SubResourceUrl => {
    let endpointUrl = entityEndpointList[entityType].url
    if (parentIri) {
      const parentType = entityTypeFromIModelOrIRI(parentIri)
      const subResource = entityEndpointList[parentType].subResource
      const subEndpointName = subResource?.[entityType]?.url

      try {
        if (!subEndpointName) {
          throw new Error("failure.nonExistingSubEndpoint", { cause: entityType + " @ " + parentIri })
        }

        endpointUrl = subEndpointName.replace("[id]", idFromIModelOrIRI(parentIri).toString()) as SubResourceUrl
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("WARNING: sub endpoint could not be found")
        captureException(err, { extra: { entityType, parentIri } })
      }
    }

    return endpointUrl
  }

  // #endregion


  /* *****************************************************************
   * specific functions that can not be handled by general functions
   *******************************************************************/


  // #region ProposalAttachment

  /**
   * An ProposalAttachment consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an IProposalAttachment to be sent to the API
   * @returns the API reply as IProposalAttachment
   */
  public createProposalAttachment = (entity: IProposalAttachment): Promise<IProposalAttachment> => {
    const formData = new FormData()

    if (entity.proposal) {
      formData.append("proposal", iriFromIModelOrIRI(entity.proposal))
    }
    if (entity.definition) {
      formData.append("definition", iriFromIModelOrIRI(entity.definition))
    }
    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }

    return this.upload(entityEndpointList[EntityType.ProposalAttachment].url, formData) as Promise<IProposalAttachment>
  }

  /**
   * An ProposalAttachment consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an IProposalAttachment to be updated
   * @returns the API reply as IProposalAttachment
   */
  public updateProposalAttachment = (entity: IProposalAttachment): Promise<IProposalAttachment> => {
    const formData = new FormData()

    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }

    return this.upload(entity["@id"], formData, { method: "PATCH" }) as Promise<IProposalAttachment>
  }

  // #endregion

  // #region TeamUpload

  /**
   * An TeamUpload consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an ITeamUpload to be created
   * @returns the API reply as ITeamUpload
   */
  public createTeamUpload = (entity: ITeamUpload, projectIri: IRI): Promise<ITeamUpload> => {
    const formData = new FormData()

    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }
    if (typeof entity.category === "string") {
      formData.append("category", entity.category)
    }
    if (typeof entity.content === "string") {
      formData.append("content", entity.content)
    }
    return this.upload(ProjektfabrikClient.getEndpointUrl(EntityType.TeamUpload, projectIri), formData) as Promise<ITeamUpload>
  }

  /**
   * An TeamUpload consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an ITeamUpload to be updated
   * @returns the API reply as ITeamUpload
   */
  public updateTeamUpload = (entity: ITeamUpload): Promise<ITeamUpload> => {
    const formData = new FormData()
    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }
    if (typeof entity.category === "string") {
      formData.append("category", entity.category)
    }
    if (typeof entity.content === "string") {
      formData.append("content", entity.content)
    }
    return this.upload(entity["@id"], formData, { method: "PATCH" }) as Promise<ITeamUpload>
  }

  // #endregion


  // #region Auth
  public requestAuthToken = (credentials: ICredentials): Promise<IAuthReply> => {
    return this.post("/authentication_token", credentials) as Promise<IAuthReply>
  }

  public refreshAuthToken = (refreshToken: string): Promise<IAuthReply> => {
    return this.post("/refresh_token", { refresh_token: refreshToken }) as Promise<IAuthReply>
  }
  // #endregion

  // #region FeedbackInvitation

  public activateFeedbackInvitation = (fbi: IFeedbackInvitation): Promise<void> => {
    return this.post(`/feedback_invitations/${fbi.id}/activate`, fbi) as Promise<void>
  }
  // #endregion



  // #region FeedbackPost

  public deleteFeedbackPostContent = (post: IFeedbackPost): Promise<void> => {
    // not the feedbackpost is deleted, but its content
    return this.delete(post["@id"] + "/delete-content") as Promise<void>
  }

  /**
   * Sents new post as a reply to an existing post
   *
   * @param post The new post.
   * @param parentIri The IRI of the already existing post
   * @returns the new post, updated
   */
  public replyFeedbackPost = (post: IFeedbackPost, parentIri: IRI): Promise<IFeedbackPost> => {
    return this.post(parentIri + "/reply", post) as Promise<IFeedbackPost>
  }

  // #endregion


  /* #region challenge */
  public transitionChallenge = (challenge: IChallenge, transition: ChallengeTransitionState): Promise<IChallenge> => {
    return this.post(challenge["@id"] + "/transition", {}, { params: { action: transition } }) as Promise<IChallenge>
  }

  /* #endregion */

  // #region partnermarket

  public lockSupportRequest = (supportRequest: ISupportRequest): Promise<void> => {
    return this.post(supportRequest["@id"] + "/lock", {}) as Promise<void>
  }

  // #endregion partnermarket

  /* #region Proposals */
  public activateProposal = (proposal: IProposal | IFundApplication): Promise<void> => {
    return this.post(proposal["@id"] + "/activate", {}) as Promise<void>
  }

  public createProposalPdf = (proposal: IProposal | IFundApplication): Promise<void> => {
    return this.post(proposal["@id"] + "/create-pdf", []) as Promise<void>
  }

  public transitionProposal = (proposal: IProposal | IFundApplication, transition: ProposalTransitionState): Promise<IChallenge> => {
    return this.post(proposal["@id"] + "/transition", {}, { params: { action: transition } }) as Promise<IChallenge>
  }

  /* #endregion */


  /* #region Program */
  // @todo update when multi-processes are implemented
  public getCurrentProgram = async (): Promise<IProgram> => {
    return this.loadEntityCollection<IProgram>(EntityType.Program, null, null)
      .then((programs) => programs["hydra:member"].shift())
      // we cannot simply return the first process from the collection but need to fetch him separately
      // as his challenges are only returned in the detailResult.
      // We can also not assume that the ID is always 1...
      .then(program => program ? this.loadSingleEntity(EntityType.Program, program.id) : null)
  }

  // @todo multi adapt to program => currently not integrated in multitenant-backend
  // https://futureprojects.atlassian.net/browse/FCP-1430
  public sendEmailToUsers = (processId: number, userMessage: IUserEmail): Promise<void> => {
    return this.post(`/processes/${processId}/contact-users`, userMessage) as Promise<void>
  }
  // @todo multi adapt to program => currently not integrated in multitenant-backend
  // https://futureprojects.atlassian.net/browse/FCP-1430
  public sendContactEmail = (processId: number, email: IContactEmail): Promise<void> => {
    return this.post(`/processes/${processId}/contact`, email) as Promise<void>
  }
  // #endregion

  // #region Project

  public activateProject = (project: IProject): Promise<IProject> => {
    return this.post(project["@id"] + "/activate", {}) as Promise<IProject>
  }

  public deactivateProject = (project: IProject): Promise<IProject> => {
    return this.post(project["@id"] + "/deactivate", {}) as Promise<IProject>
  }

  public createProject = (project: IProjectCreation | IProject): Promise<IProject> => {
    return this.post("/projects", project) as Promise<IProject>
  }

  public createProjectPdf = (project: IProject): Promise<void> => {
    return this.post(project["@id"] + "/create-pdf", []) as Promise<void>
  }

  public reportProject = (project: IProject, report: IProjectReport): Promise<void> => {
    return this.post(project["@id"] + "/report", report) as Promise<void>
  }

  public emailProjectMembers = (project: IProject, email: ITeamEmail): Promise<void> => {
    return this.post(project["@id"] + "/team-email", email) as Promise<void>
  }

  // #endregion


  // #region User
  public registerUser = (userWriteDTO: IUserWriteDTO): Promise<IUser> => {
    return this.post("/users", userWriteDTO) as Promise<IUser>
  }

  public forgotPassword = (data: IPasswordResetRequest): Promise<void> => {
    return this.post("/users/reset-password", data) as Promise<void>
  }

  public changeEmail = (data: IEmailChange): Promise<void> => {
    return this.post("/users/change-email", data) as Promise<void>
  }

  public changePassword = (data: IPasswordChange): Promise<void> => {
    return this.post("/users/change-password", data) as Promise<void>
  }

  public newPassword = (user: IUser, data: INewPasswordRequest): Promise<void> => {
    return this.post(user["@id"] + "/new-password", data) as Promise<void>
  }

  public getUserObjectRoles = (userId: string): Promise<IHydraCollection<IUserObjectRole>> => {
    // @†odo https://futureprojects.atlassian.net/browse/FCP-1448
    return this.get(replaceIdPlaceholder(entityEndpointList[EntityType.UserObjectRole].url, userId)) as Promise<IHydraCollection<IUserObjectRole>>
  }
  // #endregion

  // #region Verification
  public confirmAccountVerification = (data: IVerification): Promise<IOperationResultOutput> => {
    return this.post(`/account_verifications/${data.id}/confirm`, { 'token': data.token }) as Promise<IOperationResultOutput>
  }

  public confirmEmailChangeVerification = (data: IVerification): Promise<IOperationResultOutput> => {
    return this.post(`/email_change_verifications/${data.id}/confirm`, { 'token': data.token }) as Promise<IOperationResultOutput>
  }

  public confirmResetPasswordVerification = (data: IPasswordReset): Promise<IOperationResultOutput> => {
    return this.post(`/password_reset_verifications/${data.id}/confirm`, { 'token': data.token, 'password': data.password }) as Promise<IOperationResultOutput>
  }
  // #endregion

  // #region transition
  public transitionEntity = <Entity extends IModel, TransitionState>(entity: Entity, transitionInput: TransitionInput<TransitionState>): Promise<Entity> => {
    return this.post(entity["@id"] + "/transition", { 'action': transitionInput.action, 'message': transitionInput.message }) as Promise<Entity>
  }
  // #endregion


  // #region Meta-Data

  public getBackendCommit = async (): Promise<string> => {
    const response = await this.axios.request({ url: `/commit.txt` })
    return response.data as string
  }


  public getSysinfo = (): Promise<ISysinfo> => {
    return this.get(`/sysinfo`) as Promise<ISysinfo>
  }

  // #endregion

  // #region IOIDCAPI

  public getOIDCProviders = (): Promise<INativeHydraCollection<IOIDCProvider>> => {
    return this.get(`/oidc_providers`) as Promise<INativeHydraCollection<IOIDCProvider>>
  }

  public loginWithOIDCToken = (providerShortName: string, idToken: string): Promise<IAuthReply> => {
    return this.post(`/oidc_login`, { 'provider': providerShortName, idToken }) as Promise<IAuthReply>
  }

  // #endregion

}

export default new ProjektfabrikClient(FCP_API_ENTRYPOINT)
