import Link from "next/link"
import { Col, Row } from "reactstrap"

import Icon from "@components/common/Icon"
import HeaderIconNavigation from "@components/layout/Header/HeaderIconNavigation"
import HeaderLogo from "@components/layout/Header/HeaderLogo"
import { Routes } from "@definitions/routes"


export const PAGE_HEADER_ID = "page-header"

interface IProps {
  authTTL: number
  doLogout: (redirect: Routes) => void
  isFrontPage?: boolean
  isMarketplace?: boolean
  pageTitle: string
}

/**
 * provides the common header of the layout for every page, used by BaseLayout
 */
const Header: React.FC<IProps> = (props: IProps) => (
  // @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/banner_role
  <header role="banner" id={PAGE_HEADER_ID}>
    <Row>
      <Col className="small-gap">
        <Icon name="arrow-left" size={25} />
        <Link className="no-link-underline" href={"https://sinn-sachsen.de/oekosystem.html"}>
          {/* @todo SINN: Ins Translation-System einbinden: zusätzliche customer-translations ermöglichen */}
          Zum Ökosystem
        </Link>
      </Col>
      <Col><HeaderLogo isMarketplace={props.isMarketplace} /></Col>
      <Col>
        <HeaderIconNavigation
          authTTL={props.authTTL}
          doLogout={props.doLogout}
          isFrontPage={props.isFrontPage}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        {props.isFrontPage
          ? // option to place a header-image in a third col between logo and nav
          // used only for custom client design, image url is defined in custom-styles
          <div className="custom-frontpage-header-div" />
          : <div className="page-title">
            {props.pageTitle}
          </div>
        }
      </Col>
    </Row>
  </header>
)

export default Header